

@media (max-width: 1023px) {
      .profile-view-content-main {
            grid-template-columns: repeat(1, 1fr);
      }
      .chart-row-main {
            grid-template-columns: repeat(1, 1fr);
      }
}

@media (max-width: 767px) {
      .profile-view-content-main {
            grid-template-columns: repeat(1, 1fr);
      }


      .profile-details-card {
            flex-direction: column;
      }

      .admin-file-view-card {
            max-width: 100%;
            flex: 0 0 100%;
      }
}
