

@media (max-width: 1023px) {
      .view-details-content-flex {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
      }
      .view-details-img-flex {
            flex-direction: column;
            align-items: flex-start;
      }
      .view-category-content-main{
            margin-bottom: 15px;
      }
      .view-category-content-main .view-content-flex {
            flex-direction: column;
            align-items: flex-start;
      }
}

