/* start admin dashboard page structure css */

.admin-dashboard-left-main {
      max-width: 245px;
      width: 100%;
      background-color: var(--bg-color);
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      z-index: 999;
      border-right: 1px solid var(--main-border);
      transition: 0.5s all;

}

.admin-dashboard-containt-main {
      /* background-color: var(--body-color); */
      background-color: #efefef;
      min-height: 100vh;
      padding:80px 15px 20px 260px;
      transition: 0.5s all;
}

.admin-dashboard-main {
      width: 100%;
}


/* end admin dashboard page structure css */




.comment .admin-header-main.admin-header-deactive+.admin-dashboard-containt-main {
      padding: 88px 24px 24px 24px;
}

.comment .admin-dashboard-left-main.admin-sidebar-deactive {
      left: -250px;
} 