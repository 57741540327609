* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

html {
  scroll-behavior: smooth;
  scroll-padding-block-start: 30px;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: var(--main-black);
  background-color: var(--body-color);
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --main-white: #fff;
  --primary-color: #111827;
  --secondary-color: #6b7280;
  --selected-color: #c85803;
  --main-border: #d1d5db85;
  --main-black: #000;
  --main-red: #b90005;
  --bg-color: #3c0000;
  --body-color: #f4f5f7;
  --light-gray: #7171711f;
  --off-white: #dbd4ce;
  --green: #5cb37d;
  --gray: #adb5bd;
  --yellow: #ffa447;
  --blue: #59b4c3;
  --red: #d04848;
  --light-purple: #173ab880;
}

::selection {
  background-color: #c85803;
  /* Yellow background color for selection */
  color: #f4f5f7;
  /* Black text color for selection */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.cus-scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 3px;
  background-color: #ebebeb;
  border-radius: 100rem;
}

.cus-scrollbar::-webkit-scrollbar-thumb {
  background: #7171711f;
  border-radius: 100rem;
}

.cus-scrollbar {
  scrollbar-color: var(--bg-color) #ebebeb;
  scrollbar-width: thin;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.admin-text-decoration-none {
  text-decoration: none !important;
}

.admin-flex-all {
  display: flex;
  justify-content: space-between;
}

/* start input field design css */

.admin-form-group .admin-form-control input {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  width: 100%;
  padding: 7px 15px;
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.admin-form-group .admin-form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.admin-form-group .admin-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.admin-form-control fieldset {
  border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--main-border) !important;
}

.admin-form-control-textarea:focus-visible {
  outline: none !important;
}

.admin-input-box .admin-form-control-textarea {
  width: 100%;
  border: 1px solid var(--main-border);
  border-radius: 4px;
  padding: 4px 15px;
  height: 70px !important;
  resize: none;
  color: var(--secondary-color);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  background-color: #f5f5f5b0;
}

/* end input field design css */

/* password field */

.admin-input-box .admin-form-lable {
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
  margin: 0px 0px 6px;
  color: var(--secondary-color);
  min-height: 19px;
}

.admin-input-box {
  margin-bottom: 15px;
}

.admin-input-box .MuiInputBase-root {
  width: 100%;
  background-color: #f5f5f5b0;
}

.admin-input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.admin-form-group .admin-form-control-eye {
  border: 1px solid var(--main-border);
}

.admin-input-box .admin-form-control-eye input {
  border: 0px;
}

.admin-input-box .admin-form-control-eye input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

/* End password field */

.admin-page-border {
  border: 1px solid var(--main-border);
  border-radius: 6px;
  padding: 20px;
}

.btn-main .btn {
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 6px 10px;
  text-transform: capitalize;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  background-color: var(--main-red);
  border-radius: 4px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 6px 10px;
  text-transform: capitalize;
}

.btn-main-secondary .btn-secondary,
.btn-main-secondary .btn-secondary:hover {
  background-color: var(--secondary-color);
  border-radius: 4px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 6px 10px;
  text-transform: capitalize;
}

.border-btn-main .border-btn {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 6px 10px;
  text-transform: capitalize;
}

.orange-btn-main .orange-btn,
.orange-btn-main .orange-btn:hover {
  background-color: var(--selected-color);
  border-radius: 4px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 6px 10px;
  text-transform: capitalize;
}

.admin-common-para {
  font-size: 13px !important;
  line-height: 19px !important;
  font-weight: 400;
}

/* start switch css */

.admin-switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 400;
}

/* end switch css */

/* start pagination css */

.admin-pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--main-border);
  padding-top: 20px;
}

/* .admin-pagination-main .MuiPagination-ul {
  margin-right: 20px;
} */

.admin-pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  margin: 0 4px;
}

.admin-pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: var(--selected-color);
  color: var(--main-white);
}

.admin-pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: var(--selected-color);
  color: var(--main-white);
}

/* end pagination css */

/* start progrss bar css */

.admin-progress-bar-main {
  /* max-width: 315px; */
  width: 100%;
  position: relative;
}

.admin-progress-bar-main .admin-progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: var(--bg-color);
}

.admin-progress-bar-content {
  margin: 10px 0px 15px;
}

.admin-progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-color);
  font-weight: 500;
}

.admin-progress-bar-text {
  position: absolute;
  right: 0px;
  top: 9px;
  font-size: 12px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 500;
}

/* end progrss bar css */

/* start error text css */

.admin-input-box .admin-error-text {
  color: var(--main-red);
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  margin: 0px;
}

/* end error text css */

/* / start dropdown css / */

.admin-dropdown-box .admin-form-control {
  width: 100%;
  position: relative;
}

.admin-dropdown-select .MuiSelect-select {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  padding: 5.64px 15px;
  border-radius: 4px;
  border: 1px solid var(--main-border);
}

.admin-grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.admin-dropdown-box .MuiMenu-list .admin-drop-menuitem {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

/* / end dropdown css / */

.admin-row {
  grid-gap: 30px;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

/* start common modal css */
.admin-modal .admin-modal-inner {
  padding: 20px 15px;
  width: calc(100% - 30px);
  border: 1px solid var(--main-border);
  border-radius: 10px;
  max-height: calc(100vh - 40px);
}

.admin-modal-hgt-scroll {
  max-height: calc(100vh - 194px);
  overflow: auto;
  padding: 0 5px 0px 0px;
  margin-right: -5px;
}

.modal-footer {
  padding: 15px 0px 0px;
}

.admin-modal-header .modal-close-btn {
  padding: 0px;
  min-width: 30px;
  justify-content: flex-end;
}

.admin-modal-close-icon {
  cursor: pointer;
  height: 22px;
  width: 22px;
  object-fit: contain;
}

.admin-modal .admin-add-user-modal-inner-main {
  max-width: 600px;
}

.admin-span-text {
  color: var(--secondary-color);
  margin-left: 3px;
  font-size: 12px;
  line-height: 16px;
}

.admin-modal .admin-modal-title {
  color: var(--primary-color);
  font-size: 15px;
  line-height: 21px;
  font-weight: 600;
}

.admin-modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
  gap: 10px;
}

.admin-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 70%);
}

/* end common modal css */

/* start common table design  */

.admin-table-data-flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.admin-table-data-btn-flex {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.admin-table-data-btn-flex .admin-table-data-btn {
  min-width: 25px;
  padding: 2px;
}

.admin-table-data-img {
  margin-right: 15px;
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 5px;
}

.admin-table-img {
  height: 85px;
  width: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.admin-table-img-box {
  display: flex;
}

.table-td .admin-table-data-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
}

.page-table-main .table-container {
  background-color: transparent;
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 0px);
}

.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  padding: 7px 10px 7px 10px;
  border-bottom: 1px solid var(--main-border);
  background-color: var(--light-gray);
  background-color: #e9e9e9;
}

.page-table-main .table-td {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
  padding: 6px 10px 6px 10px;
  border-bottom: 1px solid var(--main-border);
}

.page-table-main .table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.page-table-main .table-container::-webkit-scrollbar-track {
  background: var(--main-border);
  border-radius: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
  border-radius: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end common table design  */

.admin-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.card-border {
  border-radius: 5px;
  border: 1px solid var(--main-border);
  padding: 20px;
}

.common-card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
  border-radius: 10px;
  padding: 15px;
}

.view-sales-border-card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  border: 1px solid var(--main-border);
  border-radius: 10px;
  padding: 15px;
}

/* .view-sales-inner-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
} */

.admin-body-overflow .MuiTooltip-tooltip {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--selected-color);
  border-radius: 5px;
  color: var(--main-white);
  padding: 4px 8px;
  font-size: 13px;
  line-height: 25px;
  font-weight: 500;
}

.admin-body-overflow .MuiTooltip-tooltip .MuiTooltip-arrow::before {
  color: var(--selected-color);
}

.admin-userlist-inner-btn-flex {
  display: flex;
  gap: 10px;
}

.admin-search-box {
  position: relative;
}

.admin-search-box .admin-form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.admin-search-grey-img {
  position: absolute;
  left: 13px;
  top: 10px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
}

.admin-search-box .admin-form-group .admin-form-control input {
  padding: 7px 35px;
  background-color: #fffffff7;
}

.admin-user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 15px;
}

.admin-userlist-btn-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* end admin css */

/* start user globle css */
.container {
  padding: 0px 50px 0px 50px;
  width: 100%;
  margin: auto;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* start input field design css */

.form-group .form-control input {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.form-group .form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.form-control fieldset {
  border: 1px solid var(--main-border);
  border-radius: 2px;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

/* end input field design css */

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-color: var(--primary-color);
  border-radius: 4px;
  width: 100%;
  max-width: 380px;
  color: var(--main-white);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 10px 15px;
  text-transform: capitalize;
}

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400;
}

/* start switch css */

.switch-main {
  margin-left: 20px;
}

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 400;
}

/* end switch css */

/* start pagination css */

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 27px;
}

.pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: var(--primary-color);
  color: var(--main-white);
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: var(--primary-color);
  color: var(--main-white);
}

/* end pagination css */

/* start progrss bar css */

.progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: var(--primary-color);
}

.progress-bar-content {
  margin-top: 30px;
}

.progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-color);
  font-weight: 400;
}

.progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 500;
}

/* end progrss bar css */

/* start error text css */

.error-text {
  color: var(--main-red);
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

/* end error text css */

/* / start dropdown css / */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-select .MuiSelect-select {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  padding: 10px 15px;
  border-radius: 4px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

/* / end dropdown css / */

/* start common table title */

.table-title-main .table-title-name {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
}

.table-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.table-search-flex {
  display: flex;
}

/* end common table title */

/* start uploaf file design */
.file-upload-main .file-upload-button,
.file-upload-main .file-upload-button:hover {
  border: 3px dotted var(--main-border);
  border-radius: 4px;
  height: 100px;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  padding: 5px;
}

.upload-profile-img {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.thumbnail-box {
  max-width: 235px;
  position: relative;
}

.thumbnail-box-live {
  max-width: 126px;
  position: relative;
  border-radius: 50%;
}

.thumbnail-box-live .file-upload-button,
.thumbnail-box-live .file-upload-button:hover {
  border-radius: 50%;
  overflow: hidden;
}

.upload-img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  border-radius: 5px;
  /* aspect-ratio: 1 / 0.5; */
}

/* end uploaf file design */

.admin-add-user-containt .admin-add-basic-text {
  color: var(--primary-color);
  font-weight: 600;
  margin: 0px 0px 4px;
}

.admin-add-user-containt .admin-add-section-text {
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.admin-input-box .admin-form-control-textarea::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.admin-radio-main .admin-radiogroup .MuiButtonBase-root.Mui-checked {
  color: var(--selected-color);
}

.admin-radio-main .MuiFormControlLabel-label {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.admin-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
  gap: 15px;
}

.admin-user-save-icon {
  margin-right: 8px;
  object-fit: contain;
}

.admin-tabs-main .admin-tab {
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  color: var(--primary-color);
  text-transform: capitalize;
  padding: 5px 15px;
  min-height: 30px;
}

.admin-tabs-main-box .admin-tabs-main {
  min-height: 35px;
}

.admin-tabs-main .admin-tab.Mui-selected {
  color: var(--selected-color);
}

.admin-tabs-main .MuiTabs-indicator {
  background-color: var(--selected-color);
  height: 2px;
}

.admin-tabpanel .admin-edit-highlight-text {
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 4px;
}

.admin-tabpanel .admin-edit-para-text {
  margin-bottom: 24px;
  color: var(--secondary-color);
}

.admin-tabpanel>.MuiBox-root {
  padding: 15px 0px;
}

.page-table-main .table-not-found-td {
  padding: 0px;
  border: 0px;
}

.data-not-found-main {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 9px;
  width: 100%;
  margin: 10px 0px 0px;
}

.data-not-found-main .data-not-found-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  text-align: center;
}

.admin-icons {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.round-btn-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-btn-main .admin-icons {
  margin-right: 0px;
}

.round-btn-main .round-btn {
  background-color: var(--selected-color);
  padding: 5px;
  height: 30px;
  min-width: 30px;
  width: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.admin-icons-btn-main .admin-icons-btn {
  min-width: 30px;
}

.inner-title-main {
  margin: 20px 0px 10px;
}

.admin-auto-complete-control {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  width: 100%;
}

.admin-auto-complete-control .MuiOutlinedInput-root {
  padding: 4.5px 0px 4.5px 15px !important;
}

.admin-input-box .admin-auto-complete-control .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 3px 60px 4px 0px !important;
  border: 0px;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.admin-auto-complete-control .MuiAutocomplete-root .MuiFormControl-root {
  padding: 0px;
}

.admin-profile-main .admin-profile-img {
  width: 150px;
  border-radius: 5px;
  object-fit: cover;
}

/* start loader css */
/* .loader-position{
  position: relative;
} */

.loader-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 255px);
  /* position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px; */
}

.loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}

.loader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--bg-color);
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
}

.loader span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes loader {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

/* end loader css */

.admin-header-drop-main {
  position: relative;
}

.admin-noti-badge-main {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: var(--selected-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: 0px;
}

.admin-noti-badge-main .admin-noti-badge-text {
  font-size: 11px;
  line-height: 18px;
  color: var(--main-white);
  font-weight: 400;
}

/* start select 2 css */
.admin-select2-main {
  width: 300px;
}

.admin-select2-main .select2-container--default .select2-selection--multiple {
  background-color: transparent;
  border: 1px solid var(--main-border);
}

.admin-select2-main .select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid var(--main-border);
  background-color: transparent;
}

.admin-select2-main .select2-container .select2-search--inline .select2-search__field {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.admin-select2-main .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 1px 5px 0px;
}

.admin-select2-main .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: transparent;
  border: 1px solid var(--main-border);
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

/* end select 2 css */

/* start switch css */
.admin-switch-main .admin-switch-lable {
  margin: 0px;
}

.admin-switch-main .MuiSwitch-root {
  margin: 0px;
}

/* .admin-switch-main .admin-switch-lable .MuiSwitch-root {
  margin: 0px;
  height: 17px;
} */

/* .admin-switch-main .admin-switch-lable .MuiSwitch-root .MuiSwitch-thumb {
  width: 14px;
  height: 14px;
} */
/* .admin-switch-main .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #d9d9d9 !important;
}
.admin-switch-main .MuiSwitch-root .MuiSwitch-switchBase {
  margin: 1.6px !important;
} */
/* end switch css */

.astrick-sing {
  color: var(--main-red);
}

/* start accordian design  */

.accordian-main .accordian-summary {
  background-color: var(--light-gray);
  min-height: 30px;
}

.accordian-main .accordian {
  margin-bottom: 10px;
  box-shadow: none;
}

.accordian-main .accordian-details {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  border: 1px solid var(--main-border);
  word-wrap: break-word;
}

.accordian-main .MuiAccordion-root::before {
  background-color: transparent;
}

.accordian-main .MuiAccordionSummary-content.Mui-expanded {
  margin: 5px 0px;
}

.accordian-main .MuiAccordionSummary-content {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  margin: 5px 0px;
}

.accordian-main .accordian:last-child {
  margin-bottom: 0px;
}

.accordian-main .accordian.Mui-expanded {
  margin: 10px 0;
}

.accordian-main .accordian-summary.Mui-expanded {
  min-height: 30px;
  padding: 5px 16px;
}

.accordian-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* end accordian design */

/* start delete user modal css */

.admin-user-circle-img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.admin-modal .admin-delete-modal-inner-main {
  max-width: 360px;
}

.admin-delete-modal-inner-main .admin-delete-modal-title {
  color: var(--primary-color);
  font-size: 17px;
  line-height: 19px;
  font-weight: 500;
  text-align: center;
  margin: 15px 0px 10px;
}

.admin-delete-modal-inner-main .admin-delete-modal-para {
  color: var(--secondary-color);
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.admin-delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 15px;
}

.admin-delete-modal-btn-flex .admin-modal-delete-btn,
.admin-delete-modal-btn-flex .admin-modal-delete-btn:hover {
  color: var(--main-white);
  background-color: var(--selected-color);
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

img[alt] {
  font-size: 10px;
}

.option-condition-text {
  color: var(--secondary-color);
  font-size: 10px;
  line-height: 15px;
  text-transform: capitalize;
  font-weight: 500;
}

.span-bold {
  font-weight: 700;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 12px;
  line-height: 18px;
  padding: 2px 16px;
}

.MuiList-root .admin-drop-menuitem {
  font-size: 12px;
  line-height: 18px;
}

.date-picker-body .MuiDateCalendar-root {
  width: 280px;
  height: 230px;
}

.date-picker-body .MuiDayCalendar-weekDayLabel {
  width: 32px;
  height: 25px;
}

.date-picker-body .MuiPickersDay-root {
  width: 25px;
  height: 25px;
  margin: 0px 5px;
}

.date-picker-body .MuiPickersCalendarHeader-root {
  margin-top: 15px;
  max-height: 20px;
  min-height: 20px;
}

.date-picker-body .MuiPickersCalendarHeader-label {
  font-size: 12px;
}

.date-picker-body .MuiPickersYear-root {
  flex-basis: 22%;
}

.date-picker-body .MuiPickersYear-yearButton {
  height: 28px;
  width: 68px;
  font-size: 15px;
}

.ck-content ul,
.ck-content ol {
  margin-left: 20px;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  z-index: 999;
}

.admin-date-picker-control svg {
  font-size: 15px;
}

.red {
  background-color: var(--red) !important;
}

.yellow {
  background-color: var(--yellow) !important;
}

.yellow-long {
  background-color: var(--yellow) !important;
  max-width: 120px !important;
}

.blue {
  background-color: var(--blue) !important;
}

.green {
  background-color: var(--green) !important;
}

.light-purple {
  background-color: var(--light-purple) !important;
}

.orange {
  background-color: var(--selected-color) !important;
}

.color-box .lable-btn {
  background-color: var(--secondary-color);
  font-size: 8px;
  line-height: 13px;
  color: var(--main-white);
  font-weight: 400;
  padding: 2px 7px;
  border-radius: 4px;
  text-transform: capitalize;
  max-width: max-content;
  width: 100%;
  text-align: center;
}

.color-box {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.table-td .news-id-text {
  color: var(--selected-color);
  font-weight: 600;
  margin-right: 10px;
}

.news-title-name {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.news-id-text {
  color: var(--selected-color);
  font-weight: 600;
  margin-right: 10px;
  font-size: 10px;
}

.custom-not-found-main .custom-not-found-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  text-align: center;
}

.custom-full {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-red-color {
  color: #3c0000 !important;
}

.orange-color {
  color: #c85803 !important;
}

.other-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;
}

.page-table-main.add-schedule-table-main .table {
  min-width: 1150px;
  /* max-width: max-content; */
  width: 100%;
}

.add-schedule-table-main .table .table-th:first-child,
.add-schedule-table-main .table .table-td:first-child {
  width: 20%;
}

/* .add-schedule-table-main table .table-th:nth-child(odd)  {
 width: 5%;
 background-color: red;
}
.add-schedule-table-main table .table-th:nth-child(even){
  background-color: yellow;
  width: 5%;
} */

/* start filter css */

.admin-filter-main {
  position: relative;
}

.drawer-filter-main .MuiDrawer-paper {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
}

/* .admin-filter-main .MuiDrawer-paper {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
} */

.admin-filter-header {
  background-color: var(--bg-color);
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
}

.admin-filter-header button {
  min-width: 20px;
  padding: 0;
}

.admin-filter-header .admin-filter-close-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
  cursor: pointer;
}

.admin-filter-header .admin-filter-title {
  font-size: 15px;
  line-height: 20px;
  color: var(--main-white);
  font-weight: 500;
}

.admin-filter-inner-main {
  padding: 15px;
  background-color: var(--main-white);
  height: calc(100vh - 133px);
  overflow-y: scroll;
}

.admin-filter-btn-main {
  display: flex;
  justify-content: flex-end;
}

.admin-filter-btn-main .admin-filter-cancel-btn {
  margin-right: 15px;
}

.admin-filter-footer {
  border-top: 1px solid var(--main-border);
  padding: 10px 15px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.admin-filter-checkbox-main .admin-checkbox-lable {
  margin-right: 20px;
}

/* end filter css */

.list-ads-title {
  margin-bottom: 10px;
}

.table-td .add-schedule-tale-text {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
}

.total-text {
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  color: var(--bg-color);
}

.action-menu .MuiPaper-rounded {
  box-shadow: none;
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
}

.action-menu .admin-drop-header-menuitem {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
  border-bottom: 1px solid var(--main-border);
}

.upload-document-row {
  margin: 20px 0px 0px;
  padding-bottom: 0px;
}

.upload-document-btn-end {
  justify-content: space-between;
  margin: 0px 0px 15px;
  border-bottom: 1px solid var(--main-border);
}

.document-data-flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--main-border);
}

.document-data-flex:last-child {
  border: 0px;
}

.document-delete-main .admin-table-data-btn {
  min-width: 30px;
}

.document-delete-main {
  display: flex;
  justify-content: flex-end;
}

.document-data-flex .document-lable-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  text-transform: uppercase;
}

.document-delete-main .document-delete-btn {
  min-width: 30px;
}

.document-upload-btn-main .document-upload-btn {
  border-radius: 4px;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 6px 10px;
}

.approw-person-row {
  margin: 10px 0px 0px 0px;
}

.sales-order-view-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sales-order-view-content {
  display: flex;
  gap: 10px;
  margin: 10px 0px 0px;
}

.sales-order-view-flex .sales-order-name-lable {
  font-size: 12px;
  line-height: 18px;
  color: var(--bg-color);
  font-weight: 600;
}

.sales-order-view-flex .sales-order-name-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 500;
}

.admin-modal .admin-view-sales-modal-inner-main {
  max-width: 1100px;
}

.sales-order-id-text {
  color: var(--selected-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.sales-approve-view-content {
  display: flex;
  gap: 10px;
}

.sales-approve-view-content .sales-approve-name-lable {
  font-size: 12px;
  line-height: 18px;
  color: var(--bg-color);
  font-weight: 600;
}

.sales-approve-view-content .sales-approve-name-value {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 500;
}

.view-sales-approve-box {
  margin-top: 15px;
}

.sales-attechment-box {
  border-bottom: 1px solid var(--main-border);
  padding: 5px 0px;
}

.sales-attechment-box .sales-approve-attechment-name {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 500;
}

.view-sales-approve-flex {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.attechment-card {
  padding-bottom: 10;
}

.sales-view-btn-flex {
  justify-content: flex-end;
  align-items: center;
}

.sales-attechment-flex-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.thumbnail-box .video-close-btn,
.thumbnail-box .video-close-btn:hover {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.video-close-img {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.table-cus-input input {
  padding: 0;
  border-bottom: 1px solid #e2e2e2;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.table-cus-input fieldset {
  border: none;
}

.download-btn-main .download-btn {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  min-width: 30px;
  padding: 7px 10px;
  margin-right: 10px;
}

.admin-schedulae-table-main .table {
  min-width: 1350px;
}

.admin-nc-short-table-main .table {
  min-width: 1350px;
}

.admin-pagination-main .admin-dropdown-select .MuiSelect-select {
  padding: 0px 0px 0px 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 11px;
  line-height: 17px;
}

.admin-pagination-main fieldset {
  border: 0;
}

.chart-main-box .apexcharts-toolbar {
  top: -35px !important;
}

.document-lable-flex {
  display: flex;
  gap: 10px;
  align-items: center;
}

.document-download-main .document-download-btn {
  min-width: 30px;
  border: 1px solid var(--main-border);
  padding: 2px 0px;
}

.document-lable-flex .document-lable-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  text-transform: uppercase;
}

.doucument-show-flex {
  margin-bottom: 5px;
  border: 1px solid var(--main-border);
  padding: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-top: 5px;
}

.doucument-show-flex:last-child {
  margin-bottom: 20px;
}

.document-lable-flex .document-id-text {
  color: var(--bg-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  min-width: 50px;
}

.date-box-flex .date-text {
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  color: var(--bg-color);
}

.date-box-flex {
  display: flex;
  column-gap: 50px;
  row-gap: 10px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.date-text-span {
  font-size: 11px;
  line-height: 15px;
  color: var(--primary-color);
  font-weight: 500;
  margin-left: 10px;
}

.table .table-th-trasparent {
  background-color: transparent;
  padding: 7px 10px 7px 10px;
}

.admin-previous-order-details-table-main .table {
  margin-bottom: 15px;
}

.sales-order-view-right {
  display: flex;
  gap: 25px;
}

.agency-view-content-flex {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-input-box .link-errors-text {
  padding-left: 10px;
  font-size: 11px;
}

.video-link-colum .admin-input-box {
  margin-bottom: 0;
}

.btn-main-primary .save-btn {
  min-width: 70px;
}

.btn-main-primary .edit-btn {
  min-width: 70px;
}

.btn-main-primary .schedule-btn {
  min-width: 102px;
}

.btn-main-primary .aceept-btn {
  min-width: 65px;
}

.btn-main-primary .login-btn {
  min-width: 410px;
}

.btn-main-primary .send-mail-btn {
  min-width: 106px;
}

.btn-main-primary .update-btn {
  min-width: 90px;
}

.btn-main-primary .upload-btn {
  min-width: 89px;
}

.btn-main-secondary .save-btn {
  min-width: 70px;
}

.btn-main-secondary .publish-btn {
  min-width: 70px;
}

.border-btn-main .save-draft-btn {
  min-width: 106px;
}

.border-btn-main .unblock-user {
  min-width: 107px;
}

.border-btn-main .delete-user {
  min-width: 64px;
}

.category-check-flex {
  display: flex;
  gap: 10px;
}

.admin-table-social-btn-flex {
  display: flex;
  gap: 3px;
}

.admin-news-tooltip {
  display: flex;
  gap: 8px;
  align-items: center;
}

.admin-modal .admin-add-user-modal-inner-main.order-details-modal-inner {
  max-width: 1000px;
}

.noti-box {
  /* border: 1px solid #a9acaf85; */
  min-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.noti-box .admin-icons {
  height: 13px;
  width: 13px;
  object-fit: contain;
}

/* .noti-box.active {
  background-color: var(--selected-color);
} */

.remark-row {
  margin-bottom: 15px;
}

.admin-table-social-btn-flex .admin-table-social-btn {
  min-width: 20px;
  padding: 0px;
}

.admin-social-icon-img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.created-text-value {
  font-size: 12px;
  line-height: 17px;
  color: var(--secondary-color);
}

.created-text-lable {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: var(--secondary-color);
}

.input-cus-flex .schedule-btn {
  min-width: 30px;
}

.copy-btn-main .copy-btn {
  min-width: 20px;
  padding: 0;
  position: absolute;
  right: 13px;
  top: 24%;
}

.input-cus-flex {
  display: flex;
  gap: 10px;
  align-items: center;
}

.order-show-highlight-box {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 10px;
  background-color: var(--bg-color);
  max-width: max-content;
  border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
}

.order-show-highlight-box .order-show-highlight-text {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: var(--main-white);
}

.order-show-highlight-box .order-show-highlight-value-text {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: var(--off-white);
  padding-left: 10px;
}

.badge-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.badge-deselect .badge-bg {
  background-color: var(--selected-color);
}

.badge-bg {
  background-color: var(--bg-color);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.badge-text {
  font-size: 9px;
  line-height: 14px;
  font-weight: 500;
  color: var(--main-white);
}

.order-inner-box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.order-inner-box .order-inner-lable-text {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: var(--primary-color);
}

.order-inner-box .order-inner-value-text {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: var(--secondary-color);
}

.slot-view-box {
  background-color: #f5f5f5b0;
}

.slot-view-box .slot-view-text {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  padding: 5.64px 15px;
  border-radius: 4px;
  border: 1px solid var(--main-border);
}

.view-order-title-main {
  margin-bottom: 15px;
}

.view-order-row {
  margin-bottom: 15px;
}

.admin-modal .admin-add-user-modal-inner-main.view-order-details-modal-inner {
  max-width: 700px;
}

.admin-input-box.view-order-lable-input {
  margin-bottom: 0;
}

.gap-row {
  margin-bottom: 10px;
}

.week-flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.week-title-main .week-title {
  color: var(--primary-color);
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
}

.week-day-name-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 3px;
}

.week-box {
  min-width: 80px;
}

.week-day-name-flex .week-day-text {
  color: var(--secondary-color);
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  text-align: center;
  min-width: 80px;
}

.week-box .week-day-total-text {
  color: var(--secondary-color);
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  text-align: center;
}

.week-box .week-date-text {
  color: var(--bg-color);
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  text-align: center;
  min-height: 17px;
}

.week-input-box .admin-form-group {
  max-width: 80px;
}

.week-content-main-flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.creative-percentage-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.admin-add-creative-btn-row {
  margin: 10px 0px;
}

.input-spacing-remove {
  margin-bottom: 0;
}

.creative-percentage-flex .creative-percentage-title {
  color: var(--primary-color);
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
}

.creative-percentage-input-box {
  margin-bottom: 0;
}

.creative-percentage-main {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  padding: 10px;
}

.creative-percentage-input-box .admin-form-control {
  max-width: 70px;
}

.creative-percentage-box {
  margin-bottom: 10px;
}

.creative-percentage-box:last-child {
  margin-bottom: 0;
}

.creative-percentage-wrapper .admin-sub-title-main {
  margin-bottom: 10px;
}

.admin-modal .admin-add-role-modal-inner {
  max-width: 800px;
}

.upload-video-filter-btn {
  margin: 0;
  justify-content: flex-start;
}

.admin-input-box .rmdp-container {
  width: 100%;
}

.admin-input-box .rmdp-input {
  padding: 15px;
  border: 1px solid var(--main-border);
  border-radius: 4px;
  background-color: #f5f5f5b0;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  width: 100%;
}

.date-range-row {
  margin-bottom: 10px;
}

.order-show-inner-box {
  border: 1px solid var(--main-border);
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  /* background-color: var(--main-white); */
  background-color: #f8f8f8;
}

.order-show-inner-box-view {
  margin-bottom: 10px;
}

.week-content-main-flex .admin-form-group .admin-form-control input {
  text-align: center;
  border: 1px solid #63606040;
}

.week-content-main-flex .admin-form-group .admin-form-control input::placeholder {
  text-align: center;
}

.week-content-main-flex .MuiOutlinedInput-root.Mui-disabled input {
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
  cursor: not-allowed;
}

.sub-title-value-text {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.view-booking-inner-main {
  margin-bottom: 15px;
}

.view-booking-inner-main .week-main-wapper {
  margin: 20px 0px 0px;
}

.name-highlight-box-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.name-highlight-box .name-highlight-text {
  color: var(--selected-color);
  font-weight: 600;
  font-size: 12px;
}

.name-highlight-box .name-highlight-id-text {
  color: var(--secondary-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.week-box-outer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 45px;
  margin-bottom: 15px;
  gap: 10px;
}

.week-box-outer .admin-form-control input {
  text-align: center;
  color: #000;
}

.week-box-outer .admin-form-control input.Mui-disabled {
  color: var(--bg-color);
  -webkit-text-fill-color: rgba(60, 0, 0, 1);
  font-weight: 600;
}

.view-sub-flex {
  display: flex;
  align-items: center;
}

.view-sub-flex .view-sub-lable {
  color: var(--selected-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.view-sub-flex .view-sub-data {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
}

.name-highlight-id-text {
  padding-left: 5px;
}

.view-sales-border-card .view-content-box {
  margin-bottom: 8px;
}

.view-sales-border-card .view-content-box:last-child {
  margin-bottom: 0;
}

.view-total-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--main-border);
}

.view-total-flex .view-total-lable {
  color: var(--selected-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.view-total-flex .view-total-text {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
}

.view-sales-order-wrapper {
  margin-bottom: 10px;
}

.sales-order-review-flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 5px;
}

.status-highlight-box-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border: 1px solid var(--main-border);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.status-highlight-box-flex .status-text {
  color: var(--selected-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}

.status-highlight-box-flex .status-text-span {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.table-td .admin-table-data-more-text {
  margin-bottom: 5px;
}

.table-td .admin-table-data-more-text:last-child {
  margin-bottom: 0;
}

.view-schedule-btn.Mui-disabled {
  opacity: 0.5;
}

.export-down-arrow {
  height: 14px;
  width: 14px;
  object-fit: contain;
  margin-left: 10px;
}

.admin-drop-header-menu.export-drop-menu .MuiPaper-rounded {
  margin-top: 0;
}

.admin-drop-header-menu.export-drop-menu .admin-drop-header-menuitem {
  padding: 11px 22px;
}

.card-devider {
  border-top: 1px solid var(--main-border);
  margin-bottom: 10px;
  padding-top: 10px;
}