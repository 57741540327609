@media (max-width: 1023px) {
      .admin-body-overflow {
            overflow: hidden;
      }

      .page-table-main .table {
            min-width: 1000px;
      }
}

@media (max-width: 767px) {
      .admin-auth-main {
            max-width: 300px;
      }

      .page-table-main .table {
            min-width: 1000px;
      }

      .admin-user-list-flex {
            align-items: flex-start;
            flex-direction: column;
      }

      .admin-admin-dashboard-inner-box {
            max-width: 100%;
      }

      .admin-dashboard-content .admin-page-title {
            font-size: 14px;
            line-height: 15px;
      }

      .admin-dashboard-inner-box .admin-dash-price {
            font-size: 20px;
            line-height: 25px;
      }

      .admin-filter-main .MuiDrawer-paper {
            min-width: 300px;
            max-width: 300px;
      }

      .admin-progress-bar-text {
            right: 0px;
            top: -23px;
      }

      .admin-file-view-img {
            max-width: 100%;
      }

      .admin-userlist-btn-flex {
            width: 100%;
      }

      .user-list-flex {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
      }

      .thumbnail-box {
            max-width: 100%;
      }

      .view-content-flex {
            flex-direction: column;
      }

      .admin-user-circle-img {
            width: 30px;
            height: 30px;
      }

      .admin-modal-inner .iframe-modal-close-btn,
      .admin-modal-inner .iframe-modal-close-btn:hover {
            right: -6px;
            top: -20px;
      }

}