@media (max-width: 1023px) {
    .page-table-main.role-activity-table-main .table {
        min-width: 100%;
    }
}

@media (max-width: 767px) {
    .role-permission-flex {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 25px;
    }

    .role-permission-left-main {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .role-permission-left-main .role-permission-name {
        margin-bottom: 10px;
    }
}