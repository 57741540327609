@media (max-width: 1023px) {
      .ads-manage-details-flex {
            grid-template-columns: repeat(1, 1fr);
      }
      .ads-details-main {
            grid-template-columns: repeat(1, 1fr);
      }
}

@media (max-width: 767px) {
      .ads-details-main {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
      }

      .ads-manage-details-flex {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
      }
}