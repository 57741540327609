.profile-content-main .profile-user-name {
      font-size: 17px;
      font-weight: 600;
      line-height: 22px;
      color: var(--bg-color);
      margin-bottom: 2px;
}

.profile-content-main .profile-user-email {
      font-size: 13px;
      line-height: 18px;
      color: var(--secondary-color);
      margin-bottom: 5px;
}

.profile-details-img-flex {
      display: flex;
      align-items: center;
      gap: 20px;
}
.view-details-flex{
      display: flex;
      gap: 20px;
}

.profile-view-content-main {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      width: 100%;
}
.admin-file-view-img {
      min-height: 140px;
      width: 100%;
      object-fit: cover;
      border: 1px solid var(--main-border);
      border-radius: 5px;
      aspect-ratio: 4 / 2;
      max-width: 100%;
      min-width: 100%;
}
.admin-fle-view-card {
      margin-bottom: 15px;
      max-width: 300px;
}

.profile-content-flex {
      display: flex;
      gap: 12px;
}

.profile-content-flex .profile-content-lable {
      font-size: 13px;
      font-weight: 600;
      line-height: 17px;
      color: var(--bg-color);
}
.admin-file-view-card {
      max-width: 300px;
      width: 100%;
      flex: 0 0 300px;
}
.profile-content-flex .profile-content-data {
      font-size: 12px;
      line-height: 18px;
      color: var(--secondary-color);
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
}

.chart-row-main {
      /* display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px; */
      margin: 15px 0px 0px;
}
.profile-card-main{
      margin-bottom: 15px;
}
.profile-details-content-flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
}

.profile-delete-btn {
      position: absolute;
      right: -10px;
      top: -10px;
}

.analytic-title-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.admin-modal .admin-watch-history-modal-inner-main {
      max-width: 900px;
}

.chart-title-main .chart-title {
      font-size: 13px;
      font-weight: 600;
      line-height: 19px;
      color: var(--bg-color);
      margin-bottom: 10px;
}
.watch-rasio-row{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
      gap: 20px;
}
.rasio-title-flex{
      margin: 20px 0px;
}