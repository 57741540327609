/* start user list page */

.admin-down-icon,
.admin-plus-icon {
  margin-right: 10px;
}

/* end user list page */

.user-details-card {
  margin-bottom: 15px;
}

.view-details-content-flex {
  display: flex;
  justify-content: space-between;
}

.view-details-img-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-file-upload-view-btn-main {
  position: relative;
  max-width: max-content;
  height: 120px;
  width: 120px;
  border-radius: 10px;
}

.admin-upload-view-img {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border: 1px solid let(--main-border);
  border-radius: 10px;
  min-height: 80px;
}

.admin-file-upload-view-btn-main .admin-file-upload-view-btn,
.admin-file-upload-view-btn-main .admin-file-upload-view-btn:hover {
  background-color: let(--main-white);
  border-radius: 10px;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.view-category-box {
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 2px 5px;
}
.view-category-flex-box{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}


.view-category-content-main {
  margin: 10px 0px;
}

.user-block-bg {
  padding: 3px 10px;
  background-color: #df4038;
}
.user-block-bg .user-block-text {
  font-size: 12px;
  line-height: 18px;
  color: let(--main-white);
  text-align: center;
}
.admin-input-box.add-admin-user-input {
  margin-bottom: 0px;
}
.admin-manage-user-table-main .table {
  min-width: 1200px;
}
.reaction-img {
  height: 23px;
  width: 23px;
  object-fit: contain;
}

/* .tableFixHead {
  overflow-y: auto;
  height: 348px;
}

.tableFixHead .table-head .table-th {
  position: sticky;
  top: 0px;
} */

.border-btn-main .border-btn.border-link {
  padding: 2px 10px;
  text-wrap: nowrap;
  text-decoration: none;
  color: var(--main-white);
  font-size: 8px;
  border: 1px solid #5cb37d;
  background-color: #5cb37d;
}
.user-view-details-img-flex {
  width: 100%;
}
.user-view-content-main {
  width: 100%;
}
.block-icon {
  margin-right: 10px;
}
.status-lable {
  padding: 0.5px 0px;
  border-radius: 4px;
  color: #5cb37d;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.status-lable-red {
  padding: 0.5px 0px;
  border-radius: 4px;
  color: #d94646;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.status-lable-orang {
  padding: 0.5px 0px;
  border-radius: 4px;
  color: #ffa447;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.admin-table-data-flex {
  display: flex;
  gap: 5px;
}
.admin-main-data-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}
.avtar-icons {
  height: 28px;
  width: 28px;
  object-fit: contain;
}
.admin-table-avatar-icon-box {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
