.page-table-main.admin-web-series-table-main .table {
  min-width: 1500px;
}

.cast-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* start accordian design  */

.inner-table-title-main .inner-table-title {
  font-size: 12px;
  line-height: 18px;
  color: var(--bg-color);
  font-weight: 500;
  text-align: center;
  border-bottom: 2px solid var(--bg-color);
  max-width: max-content;
}

.accordian-main .accordian-series-summary.Mui-expanded {
  min-height: 35px;
}

.accordian-main .accordian-series-summary {
  padding: 0px;
  display: block;
  min-height: initial;
}

.accordian-main .accordian-series-details {
  padding: 0px;
}

.inner-table-title-main {
  margin-bottom: 15px;
}

.admin-view-acc-summary-main .admin-view-web-series-table-main .table-th {
  background-color: #c858038c;
}

.admin-view-web-series-table-main {
  width: 100%;
}

.page-table-main .table-td .table-td-inner {
  /* padding: 0; */
  border: none;
}

.page-table-main .table-td.accordian-inner-td {
  padding: 0px;
}