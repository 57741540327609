.post-video-content-flex {
      margin-bottom: 5px;
      flex-direction: column;
      gap: 5px;
}

.uoload-post-video-main {
      border: 1px dashed #e1d5d5;
      border-radius: 5px;
      padding: 5px;
}
.admin-user-post-table-main .table{
      min-width: 1050px;
}