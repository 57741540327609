@media (max-width: 1023px) {
  .view-add-common-card .view-advertiser-content-main {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .view-add-common-card .view-advertiser-content-main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .nc-details-content-main {
    grid-template-columns: repeat(1, 1fr);
  }

  .company-details-content-main {
    grid-template-columns: repeat(1, 1fr);
  }

  .client-details-content-main {
    grid-template-columns: repeat(1, 1fr);
  }

  .advertisement-details-content-main {
    grid-template-columns: repeat(1, 1fr);
  }
  .document-upload-btn-main .document-upload-btn {
    font-size: 8px;
    line-height: 16px;
    padding: 5px 5px;
}
.document-data-flex {
  grid-template-columns: repeat(1, 1fr);
}
}
