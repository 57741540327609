
/* start admin edit user page */

.admin-edit-profile-flex {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
}
.edit-profile-row{
      display: grid;
      grid-template-columns: repeat(2 ,1fr);
      column-gap: 20px;
}

/* end admin edit user page */

/* start file upload img css */

.admin-file-upload-btn-main .admin-file-upload-btn,
.admin-file-upload-btn-main .admin-file-upload-btn:hover {
      background-color: #FFFFFF;
      border-radius: 50%;
      height: 17px;
      width: 17px;
      min-width: 17px;
      padding: 0px;
      position: absolute;
      right: 0px;
      bottom: 13px;
      box-shadow: 0px 3px 6px #00000029;
}

.admin-file-upload-btn-main {
      position: relative;
      max-width: max-content;
      height: 80px;
      width: 80px;
      border-radius: 50%;
}

.admin-upload-profile-img {
      height: 80px;
      width: 80px;
      object-fit: cover;
      border: 1px solid var(--main-border);
      border-radius: 50%;
      min-height: 80px;
}

.admin-upload-icon-img {
      height: 10px;
      width: 10px;
      object-fit: contain;
}

/* end file upload img css */