/* start admin dashboard page */

.admin-page-title-main .admin-page-title {
  color: var(--primary-color);
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.admin-dashboard-inner-box {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.admin-dashboard-inner-box .admin-dash-text {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.admin-dashboard-inner-box .admin-dash-price {
  color: var(--bg-color);
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.admin-dash-icons {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.admin-dash-icon-box {
  background-color: #d2d3d5;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-sub-title-main .admin-sub-title {
  color: var(--bg-color);
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  position: relative;
}

.admin-sub-title-main .admin-sub-title::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 35px;
  background-color: var(--bg-color);
  left: 0px;
  bottom: -2px;
}

.view-btn-main .view-order-btn {
  font-size: 12px;
  line-height: 18px;
}

.view-btn-main .view-order-btn:hover {
  font-size: 12px;
  line-height: 18px;
  background-color: var(--selected-color);
  color: #ffffff;
}

.admin-dash-card-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}

.admin-paid-lable-dots {
  background-color: #04ab47;
}

.admin-paid-lable {
  color: #04ab47;
}

.admin-table-lable-dots {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}

.admin-table-lable-flex {
  display: flex;
  align-items: center;
}

.admin-failed-lable-dots {
  background-color: #e4004d;
}

.admin-failed-lable {
  color: #e4004d;
}

.admin-pending-lable-dots {
  background-color: #f39c12;
}

.admin-pending-lable {
  color: #f39c12;
}

.admin-dashboad-row {
  margin-bottom: 15px;
}

/* end admin dashboard page */

.dash-dropdown-box .admin-dropdown-box {
  width: 205px;
}

.dash-dropdown-box .admin-dropdown-select {
  background-color: #ffffff63;
}

.slider-main .slider {
}

.slider-img {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.slider-content-box .slider-title-text {
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  color: var(--primary-color);
  text-transform: capitalize;
  margin: 5px 0px;
}

.slider-content-box .slider-para-text {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
}

.dash-slider-row {
  margin: 15px 0px 0px;
}

.slider-main .slider-owl .owl-dots .owl-dot span {
  width: 28px;
  height: 5px;
  margin: 0px 5px;
  background: #dfdfdf;
  border-radius: 4px;
}

.slider-main .slider-owl .owl-dots .owl-dot.active span,
.slider-main .slider-owl .owl-dots .owl-dot:hover span {
  background-color: #ee9521;
}

.dashboard-chart-main,
.chart-col {
  height: 100%;
}

.admin-dashboard-box {
  position: relative;
}

.admin-dashboard-box::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 6px;
  border-radius: 10px 0px 0px 10px;
  background-color: var(--selected-color);
  -webkit-border-radius: 10px 0px 0px 10px;
  -moz-border-radius: 10px 0px 0px 10px;
  -ms-border-radius: 10px 0px 0px 10px;
  -o-border-radius: 10px 0px 0px 10px;
}

.flex-full {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headline-flex {
  display: flex;
  width: 100%;
}

.headline-img {
  height: 50px;
  width: 100%;
  object-fit: cover;
  max-width: 50px;
  flex: 0 0 50px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.headline-content-box {
  padding-left: 10px;
  flex: 1;
}

.headline-content-box .headline-title {
  font-size: 11px;
  line-height: 15px;
  color: var(--primary-color);
  font-weight: 400;
}

.headline-highlite-flex {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
}

.headline-highlite-box {
  background-color: #ede9ea;
  padding: 2px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.headline-highlite-box .headline-highlite-text {
  font-size: 11px;
  line-height: 15px;
  color: var(--primary-color);
  font-weight: 400;
}

.headline-view-flex {
  display: flex;
  align-items: center;
  gap: 7px;
}

.headline-view-icon {
  height: 15px;
  width: 15px;
  object-fit: cover;
  margin-right: 5px;
}

.headline-view-text {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.headline-row {
  margin: 20px 0px;
}

.slider-item-img-box {
  position: relative;
}

.slider-item-img-box .read-more-btn,
.slider-item-img-box .read-more-btn:hover {
  position: absolute;
  background-color: var(--main-white);
  border-radius: 30px;
  color: var(--primary-color);
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  padding: 3px 6px;
  text-transform: capitalize;
  right: 5px;
  bottom: 5px;
}

.right-arrow-icon {
  height: 12px;
  max-width: 11px;
  object-fit: contain;
  margin-left: 10px;
}

.slider-category-img {
  object-fit: contain;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
  max-width: 60px;
}

.slider-content-box .slider-category-title-text {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
  text-align: center;
}

.slider-item-img-box {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-category-item-box {
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.slider-category-item-box:hover {
  background-color: var(--selected-color);
}
.slider-category-item-box:hover
  .slider-content-box
  .slider-category-title-text {
  color: #fff;
}
.owl-prev img,
.owl-next img {
  height: 20px;
  width: 20px;
}

.calegory-slider-main .calegory-slider-owl.owl-theme .owl-nav [class*="owl-"] {
  width: 35px;
  background-color: transparent;
}
.calegory-slider-main .owl-prev {
  position: absolute;
  top: -35px;
  right: 31px;
}
.calegory-slider-main .owl-next {
  position: absolute;
  top: -35px;
  right: 0px;
}
.dash-live-news-title-flex{
  margin-bottom: 20px;
}
.admin-dashboad-live-news-row{
  margin-bottom: 10px;
}
.dash-colors-box-main{
  background-color: #eccbd0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 15px;
}
.dash-colors-count-box-main {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  /* background-color: #f4f5f7; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.dash-colors-icons{
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.dash-colors-count-box-main .dash-colors-count-text{
  color: var(--main-white);
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.dash-colors-box-main .dash-colors-titles-main{
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
.dash-colors-row-main{
  margin-bottom: 20px;
}
.dash-icon-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.dash-colors-icon-box-main{
  display: flex;
}

.table-cursor {
  cursor: pointer;
}