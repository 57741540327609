

@media (max-width: 1023px) {
      .admin-close-icon {
            height: 23px;
            width: 23px;
            object-fit: contain;
            display: block;
      }

      .admin-sidebar-logo-main {
            padding: 15px 10px;
            min-height: auto;
      }

      .admin-header-main.admin-header-deactive .admin-sidebar-logo {
            display: block;
            margin-left: 0px;
            width: 100px;
      }

      .admin-header-right .admin-header-icon-box {
            margin-right: 5px;
      }

      .mobile-sidebar-header .admin-sidebar-close-btn {
            display: block;
            min-width: 30px;
      }

      .mobile-sidebar-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
      }

      .admin-sidebar-deactive .admin-close-icon {
            display: none;
      }

}

@media (max-width: 767px) {
      .admin-bergurmenu-icon {
            height: 20px;
            object-fit: contain;
            width: 20px;
      }

      .admin-header-icon-box {
            margin-right: 15px;
      }

      .admin-header-row .admin-bergur-button {
            padding: 0px;
            min-width: 37px;
      }

      .admin-header-sidebar-logo {
            order: 2;
      }

      .admin-sidebar-logo {
            width: 130px;
      }


      .admin-search-main {
            width: 100%;
      }

      .admin-userlist-inner-btn-flex {
            justify-content: flex-end;
            width: 100%;
      }
}

