/* start admin sidebar css */
.admin-sidebar-logo-main .sidebar-close-btn {
  display: none;
}

.admin-header-deactive .admin-header-logo-main {
  flex-direction: row-reverse;
}

.admin-sidebar-icons {
  margin-right: 8px;
  height: 18px;
  width: 16px;
  object-fit: contain;
}

.admin-sidebar-logo {
  width: 130px;
  object-fit: contain;
  cursor: pointer;
}

.admin-close-icon {
  display: none;
}

.admin-sidebar-logo-main {
  padding: 15px 10px 0px;
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-sidebar-list-main {
  padding: 16px 10px;
  overflow-y: auto;
  height: calc(100vh - 110px);
}

.admin-header-drop-main .admin-drop-header-btn {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-list {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
  padding: 0px;
  /* justify-content: center; */
}
.admin-sidebar-deactive .admin-sidebar-list-main .admin-sidebar-listitem {
  justify-content: center;
}

.admin-sidebar-list-main .admin-sidebar-link {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: var(--off-white);
  display: flex;
  padding: 6px 6px 6px 6px;
  margin-bottom: 3px;
  width: 100%;
  text-decoration: none;
  transition: 0.5s all;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
  color: #ffffff;
  background-color: var(--selected-color);
  border-radius: 4px;
}

/* .admin-submenu-link-box {
      width: 100%;
} */

.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
  position: absolute;
  right: -4px;
  top: 5px;
  color: #dbd4ce;
  height: 20px;
  width: 20px;
}

.admin-submenu-main {
  margin-left: 20px;
}

.admin-sidebar-deactive .admin-submenu-main {
  margin-left: 0px;
}

.mobile-sidebar-header .admin-sidebar-close-btn {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 2px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end admin sidebar css */

/* start admin header css */

.admin-header-main {
  background-color: var(--main-white);
  position: fixed;
  top: 0px;
  left: 245px;
  right: 0px;
  z-index: 9;
  padding: 15px 15px 13px 10px;
  border-bottom: 1px solid var(--main-border);
  transition: 0.5s all;
}

.admin-header-main.active .admin-sidebar-logo {
  opacity: 0;
  display: none;
}

.admin-header-logo-main .admin-bergur-button {
  padding: 0px;
  min-width: 30px;
}

.admin-header-main.admin-header-deactive .admin-sidebar-logo {
  opacity: 1;
  transition: 0.5s all;
  margin-left: 10px;
  height: 35px;
  display: none;
}

.admin-header-logo-main {
  display: flex;
}

.admin-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.admin-header-right .admin-header-icon-box {
  padding: 0px;
  min-width: 30px;
}

.admin-header-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.admin-header-profile-icon {
      height: 36px;
      width: 36px;
      object-fit: cover;
      border-radius: 50%;
}

/* end admin header css */

.admin-header-profile-icon {
  height: 36px;
  width: 36px;
  object-fit: cover;
  border-radius: 50%;
}

/* end admin header css */

/* start header profile dropdown  */

.admin-flex-drop-main .admin-header-drop {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--secondary-color);
  text-align: start;
  margin-left: 12px;
  text-transform: capitalize;
}

.admin-flex-drop-main .admin-header-drop-title {
  font-weight: 700;
}

.admin-flex-drop-main {
  display: flex;
  align-items: center;
}

.admin-drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.admin-drop-header-menu .MuiPaper-rounded {
  box-shadow: 10px 10px 20px #0000001a;
  border: 1px solid var(--main-border);
  margin-top: 13px;
  border-radius: 1px;
}

.admin-drop-header-icon {
  margin-right: 10px;
}

.admin-drop-header-menu .admin-drop-header-menuitem {
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary-color);
  font-weight: 400;
  border-bottom: 1px solid var(--main-border);
  padding: 11px 16px;
}

.admin-drop-header-menu ul {
  padding: 0px;
}

/* end header profile dropdown  */

/* start auth footer social meadia */

.social-main .social-ul {
  padding: 0px;
  list-style-type: none;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-main .social-ul .social-li {
  padding: 0px;
  margin: 0 10px 0 0;
  width: max-content;
}

.social-main .social-ul .social-li:last-child {
  margin: 0 0px 0 0;
}

.social-box {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid var(--main-border);
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

/* end auth footer social meadia */

/* start sidebar with icons */

.admin-dashboard-left-main.admin-sidebar-deactive {
  left: 0px;
  max-width: 90px;
}

.admin-sidebar-deactive .admin-sidebar-logo {
  width: 50px;
}

.admin-header-main.admin-header-deactive {
  left: 90px;
}

.admin-header-main.admin-header-deactive + .admin-dashboard-containt-main {
  padding: 80px 24px 24px 105px;
}

.admin-sidebar-deactive .admin-sidebar-link-text {
  display: none;
}

.admin-sidebar-deactive .admin-sidebar-submenulist .admin-sidebar-link {
  margin-left: 0px;
}

.admin-sidebar-deactive .admin-sidebar-icons {
  margin-right: 0px;
}

.admin-sidebar-deactive .admin-sidebar-list-main .admin-sidebar-link {
  width: initial;
  padding: 6px 6px 6px 6px;
}

/* end sidebar with icons */
.admin-noti-drop-menu .MuiPaper-rounded {
  margin-left: auto;
  margin-top: 0;
  max-width: 290px;
  min-width: 290px;
  right: 15px !important;
  top: 67px !important;
  width: 100%;
}
.noti-img-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.admin-dashboard-left-main.active .admin-submenu-link-box {
  width: 100%;
}
.noti-content-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.admin-noti-drop-menu .admin-drop-header-menuitem {
  padding: 5px 10px;
  border-bottom: 1px solid var(--main-border);
}
.admin-noti-drop-menu .admin-drop-header-menuitem:last-child {
  border-bottom: 0;
}
.admin-noti-drop-menu .MuiList-root {
  height: 203px;
  padding-top: 0px;
}
.noti-icons {
  height: 13px;
  width: 13px;
  object-fit: contain;
}
.noti-img-box {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 1px solid var(--main-border);
  display: flex;
  justify-content: center;
  align-items: center;
}
.noti-content-box .noti-content-title {
  font-size: 10px;
  line-height: 14px;
  color: var(--secondary-color);
  font-weight: 500;
}
.noti-content-by-text span {
  font-weight: 700;
  color: var(--secondary-color);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 80px;
  display: inline-block;
}
.noti-content-box .noti-content-by-text {
  font-size: 10px;
  line-height: 14px;
  color: var(--secondary-color);
  font-weight: 400;
  display: flex;
  gap: 5px;
}
.noti-content-box .noti-time-text {
  font-size: 10px;
  line-height: 14px;
  color: var(--bg-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: right;
}
.noti-content-box .noti-time-ago-text {
  font-size: 10px;
  line-height: 14px;
  color: var(--secondary-color);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: right;
  max-width: 70px;
}

.admin-dashboard-left-main.active .admin-submenu-link-box {
      width: 100%;
}

.admin-sidebar-deactive .badge-bg {
      position: absolute;
      top: 8px;
      width: 20px;
      right: -5px;
}
