.site-settings-flex {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.site-setting-row {
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(3 , 1fr);
  align-items: center;
}
.site-settings-key-row{
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(2 , 1fr);
  align-items: center;
}
.site-settings-key-row .admin-input-box{
  margin-bottom: 0;
}
.genertate-api-key-btn-main{
  width: 100%;
  max-width: max-content;
}
.admin-input-box.site-setting-input-box {
  width: 100%;
}
.site-setting-title-flex{
  display: flex;
  gap: 10px;
}
.site-settings-col:last-child {
  margin-bottom: 0px;
}

.site-settings-card {
  margin: 10px 0px 0px;
}

.site-settings-contnet-box .site-settings-title {
  color: var(--primary-color);
  font-size: 13px;
  line-height: 22px;
  font-weight: 600;
  position: relative;
}

.site-settings-contnet-box .site-settings-title::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 40px;
  background-color: var(--selected-color);
  left: 0px;
  bottom: 0px;
}
.site-key-input-flex{
  position: relative;
  width: 100%;
}
.info-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  position: absolute;
  right: 13px;
}

.site-settings-contnet-box .site-setting-time-input-box {
  margin: 0px;
  width: 100%;
}

.site-settings-contnet-box .site-settings-lables {
  color: var(--secondary-color);
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

.site-settings-card-inner-main .site-settings-flex-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.show-intrest-flex-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-setting-data-flex {
  display: flex;
  gap: 20px;
}

.site-settings-contnet-box .site-setting-event-input-box {
  max-width: 600px;
  margin: 0px;
  width: 100%;
}

/*  */
.site-settings-contnet-box .site-setting-event-input-box .MuiInputBase-root {
  padding: 5px;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  /* display: grid;
      grid-template-columns: repeat(3,1fr); */
}

.site-setting-event-input-box input {
  padding: 0px;
  width: max-content;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.site-setting-event-input-box fieldset {
  border: 1px solid var(--main-border);
}

.site-setting-event-input-box .chip-control {
  border: 1px solid var(--main-border);
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  justify-content: flex-start;
  height: 26px;
  width: max-content;
}

.site-setting-event-input-box .chip-control .MuiChip-deleteIcon {
  font-size: 15px;
}

.site-setting-event-input-box
  .MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.site-info-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}

/* start file upload img css */

.admin-file-browse-btn-main .admin-file-browse-btn,
.admin-file-browse-btn-main .admin-file-browse-btn:hover {
  background-color: var(--bg-color);
  border-radius: 10px;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: -10px;
  box-shadow: 0px 3px 6px #00000029;
  top: -8px;
}

.admin-file-browse-btn-main {
  position: relative;
  max-width: max-content;
  height: 80px;
  width: 170px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid var(--main-border);
}

.admin-browse-profile-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.admin-browse-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.site-logo-browse-main {
  margin: 20px 0px 0px;
}

/* end file upload img css */

.admin-file-browse-btn-main .admin-file-browse-btn-bottom,
.admin-file-browse-btn-main .admin-file-browse-btn-bottom:hover {
  bottom: -8px;
  top: unset;
  right: -10px;
  left: unset;
}

.site-settings-contnet-box .option-condition-text {
  margin-top: 10px;
  display: block;
}
.site-title-space{
  margin-bottom: 15px;
}