.gender-dropdown-box {
  max-width: 70px;
  width: 100%;
}

.gender-drpdown-flex {
  display: flex;
  align-items: center;
}
.admin-input-box.admin-add-event-input-box {
  margin-bottom: 0px;
}

.advertisement-card {
  margin-bottom: 20px;
}
.advertisement-card .admin-sub-title-main {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-advertiser-details-card .admin-sub-title-main {
  margin-bottom: 15px;
}

.view-advertiser-content-main .view-content-flex .view-content-lable {
  min-width: max-content;
}
.view-advertiser-content-main .view-content-box:last-child {
  margin-bottom: 0px;
}
.view-add-common-card {
  margin-bottom: 10px;
}
.admin-input-box.advertiser-filter-input {
  margin-bottom: 0px;
}
.filter-card {
  margin-bottom: 15px;
}

body .select-check-itme {
  padding: 0px 0px 5px 0px;
}
body .select-check-box {
  padding: 0px 10px;
}
body .select-check-box svg {
  font-size: 17px;
  color: #aeb2b7fa;
}
body .select-check-text .MuiTypography-root {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.admin-auto-complete-control
  .admin-check-dropdown-select.MuiOutlinedInput-root {
  padding: 0px !important;
}

.admin-date-input-main .date-rangepicker-main {
  min-width: 100% !important;
  max-width: 100%;
  width: 100%;
}
.admin-date-input-main .date-rangepicker-main input {
  padding: 7px 14px;
  border: 1px solid var(--main-border);
  border-radius: 4px;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.admin-date-input-main .date-rangepicker-main fieldset {
  border: 0px;
}
.admin-date-input-main .MuiStack-root {
  padding: 0px;
}
.admin-date-input-main .MuiInputBase-root {
  padding-right: 0px;
}
.MuiDateRangeCalendar-root > div:first-child {
  display: none;
}
.filter-btns {
  margin: 0 0 1px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.filter-btns .btn-main-primary .btn-primary,
.filter-btns .btn-main-primary .btn-primary:hover {
  padding: 9.14px 10px;
  background-color: var(--selected-color);
}
.event-add-flex {
  display: flex;
  gap: 15px;
  align-items: center;
}
.event-add-flex .add-event-btn {
  min-width: 30px;
}
.event-add-flex .admin-add-event-input-box {
  width: 100%;
}
.admin-advertiser-table-main .table {
  min-width: 1400px;
}
.view-add-common-card .view-advertiser-content-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
/* .view-advertiser-details-card{
  display: grid;
  grid-template-columns: repeat(2 , 1fr);
  gap: 10px;
} */
.add-row-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.add-row-flex .add-row-btn {
  min-width: 30px;
}
.admin-incress-dicress-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}
.add-digit-row .add-digit-text {
  color: var(--bg-color);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}
.add-digit-row {
  display: flex;
  gap: 10px;
}
.add-digit-input-box {
  width: 100%;
}
.add-row-input-box {
  flex: 1;
}
.billing-info-btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-user-btn-flex.advertiser-btn {
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;
}
.nc-details-content-main{
  display: grid;
  grid-template-columns: repeat(3 , 1fr);
  gap: 10px;
}
.company-details-content-main{
  display: grid;
  grid-template-columns: repeat(3 , 1fr);
  gap: 10px;
}
.advertisement-details-content-main {
  display: grid;
  grid-template-columns: repeat(2 , 1fr);
  gap: 10px;
}
.client-details-content-main{
  display: grid;
  grid-template-columns: repeat(3 , 1fr);
  gap: 10px;
}
.event-details-content-main .view-content-box{
  margin-bottom: 10px;
}
.event-details-content-main .view-content-box:last-child{
  margin-bottom: 0px;
}
.media-platfom-flex{
  margin-bottom: 5px;
}
.family-details-flex{
  margin-bottom: 5px;
}
.advertisemnt-btn-flex{
  margin-bottom: 15px;
}
.admin-search-btn-main .admin-search-btn {
  min-width: 30px;
  height: 30px;
  padding: 0px;
}
.admin-export-flex{
  display: flex;
  gap: 20px;
}
.admin-title-flex-main{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}