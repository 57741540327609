@media (max-width: 1023px) {
      .page-table-main.sponser-activity-table-main .table {
            min-width: 100%;
      }

      .sponser-activity-view-main {
            grid-template-columns: repeat(1, 1fr);
      }
}

@media (max-width: 767px) {
      .sponser-activity-view-main {
            grid-template-columns: repeat(1, 1fr);
      }
}