.brif-content-main .view-content-box {
      margin-bottom: 10px;
}

.brif-content-card {
      width: 100%;
}

.cast-and-crew-row {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
}

.admin-upload-cast-img {
      height: 70px;
      width: 70px;
      object-fit: cover;
      border: 1px solid var(--main-border);
      border-radius: 10px;
      margin: auto;
      display: flex;
}

.types-flex {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
}

.types-box {
      border: 1px solid var(--main-border);
      border-radius: 5px;
      padding: 2px 10px;
}

.types-box .types-text {
      font-size: 12px;
      line-height: 18px;
      color: var(--secondary-color);
      text-align: center;
      min-height: 18px;
}

.cast-box .cast-name {
      font-size: 10px;
      line-height: 14px;
      color: var(--secondary-color);
      text-align: center;
}

.cast-box .crew-name {
      font-size: 12px;
      line-height: 18px;
      color: var(--bg-color);
      text-align: center;
      font-weight: 600;
}

.admin-input-box.admin-add-movie-input-box {
      margin-bottom: 0px;
}

.copy-icon {
      height: 17px;
      width: 17px;
      object-fit: contain;
}

.view-content-box .copy-btn-main {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 25px;
      padding: 0px;
}

.cast-flex .view-content-lable {
      font-size: 13px;
      font-weight: 600;
      line-height: 17px;
      color: var(--bg-color);
      min-width: 115px;
      margin-bottom: 10px;
}

.reaction-icon-flex {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      margin-top: 15px;
}

.review-flex {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      margin-top: 15px;
}

.view-content-data-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
}

.avg-reaction-data {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
}

.review-box {
      border: 1px solid var(--main-border);
      border-radius: 5px;
      padding: 5px 10px;
}

.review-box .review-title {
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      color: var(--bg-color);
      text-align: center;
      margin-bottom: 5px;
}

.review-box .review-data {
      font-size: 12px;
      line-height: 18px;
      color: var(--secondary-color);
      text-align: center;
      min-height: 18px;
}

.view-tabs-main {
      margin: 20px 0px;
}

.movie-activity-log-main {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 15px;
      row-gap: 10px;
}

.admin-activity-thumbnail-img {
      height: 100%;
      max-height: 70px;
      width: 70px;
      object-fit: contain;
      border-radius: 10px;
}