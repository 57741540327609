.support-flex{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
}
.support-flex .support-lable{
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    color: var(--bg-color);
    /* min-width: 110px; */
}
.support-flex .support-data{
    font-size: 12px;
    line-height: 18px;
    color: var(--secondary-color);
}