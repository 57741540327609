@media (max-width: 1023px) {
      .view-content-main {
            grid-template-columns: repeat(1, 1fr);
      }
}

@media (max-width: 767px) {
      .admin-modal-user-btn-flex.add-news-btn-flex {
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: column;
      }

      .view-details-card {
            flex-direction: column;
      }

      .add-news-btn-flex {
            flex-direction: column;
      }

      .add-news-btn-flex button {
            width: 100%;
      }

      .add-news-btn-flex .border-btn-main,
      .add-news-btn-flex .btn-main-secondary,
      .add-news-btn-flex .btn-main-primary {
            width: 100%;
      }

      .video-activity-logview-main {
            grid-template-columns: repeat(1, 1fr);
      }
     
}