.admin-input-box.add-ads-input-main {
      margin-bottom: 0px;
}

.add-ads-card {
      margin-bottom: 15px;
}

.add-ads-card .admin-sub-title-main {
      margin-bottom: 10px;
}

.ads-info-flex {
      display: flex;
      align-items: center;
      gap: 15px;
}

.ads-info-flex .admin-form-group {
      width: 100%;
}

.ads-manage-details-main {
      margin: 15px 0px 0px;
}

.ads-manage-details-main .admin-sub-title-main {
      margin-bottom: 10px;
}

.view-ads-main-box {
      width: 100%;
}
/* 
.view-ads-main-box .view-content-box {
      margin-bottom: 10px;
} */

.view-ads-main-box .view-content-box:last-child {
      margin-bottom: 0px;
}
.ads-managment-table .table{
      min-width: 1300px;
}
.filter-row{
      align-items: flex-end;
}
.admin-input-box .add-new-page-link{
      font-size: 13px;
      line-height: 20px;;
      font-weight: 600;
      margin: 0px 0px 6px;
      color: var(--secondary-color);
      min-height: 19px;
      text-decoration: none;
}
.ads-details-main{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
      gap: 10px;
}
.ads-manage-details-flex{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
      gap: 10px;
}
.view-row-content-box{
      margin: 10px 0px 0px;
}
.view-row-content-box .view-content-box {
      margin-bottom: 10px;
}
.news-id-text{
      cursor: pointer;
}
.view-ads-row{
      margin-top: 20px;
}
.admin-save-btn-main .admin-save-user-btn.Mui-disabled{
      color: #d6b8b8;
}
.ads-manage-details-main .ads-manage-details-flex{ 
      grid-template-columns: repeat(1, 1fr);
}