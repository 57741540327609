

@media (max-width: 1023px) {
      .admin-auth-left-main {
            display: none;
      }

      .admin-auth-right-main {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
      }

      .login-auth-bg {
            height: 50vh;
            background-size: cover;
      }
}

