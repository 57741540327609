

@media (max-width: 1023px) {
      .site-settings-contnet-box .site-setting-event-input-box .MuiInputBase-root {
            grid-template-columns: repeat(2, 1fr);
      }
}

@media (max-width: 767px) {
      .site-settings-flex {
            align-items: flex-start;
            flex-direction: column;
      }

      .site-setting-data-flex {
            gap: 2px;
            align-items: flex-start;
            flex-direction: column;
      }

      .site-settings-col {
            align-items: flex-start;
            gap: 7px;
            flex-direction: column;
      }

      .site-settings-contnet-box .site-setting-event-input-box .MuiInputBase-root {
            grid-template-columns: repeat(1, 1fr);
      }

      .site-setting-event-input-box .MuiFormControl-root {
            width: 100%;
      }

      .site-settings-contnet-box {
            width: 100%;
      }

      .admin-input-box.site-setting-input-box {
            max-width: 100%;
            min-width: 100%;
      }
}

