.cms-page-content-web-main {
  margin: 15px;
}

.cms-page-content-web-main p {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

.cms-page-content-web-main strong {
  color: var(--bg-color);
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.cms-page-content-web-main ul {
  padding: 0 0 0 15px;
  margin: 0px;
}

.cms-page-content-web-main li {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

.cms-page-content-web-main ol {
  padding: 0 0 0 15px;
  margin: 0px;
}

.cms-page-content-web-main b {
  color: var(--bg-color);
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
}

.cms-page-content-web-main i {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: var(--bg-color);
}
.cms-page-content-web-main h1 {
  color: var(--bg-color);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.cms-page-content-web-main h2,
h3,
h4,
h5,
h6 {
  color: var(--bg-color);
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
}

.cms-page{
  background-color: black;
}